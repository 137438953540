const he = require('he')

async function generateTable(cmsData) {
  const { tenants } = cmsData

  const brentwoodRoutesRes = await fetch('https://theamazingbrentwood.com/wp-json/')
  const brentwoodRoutes = await brentwoodRoutesRes.json()

  const shopLimit = brentwoodRoutes.routes['/wp/v2/store'].endpoints.find(e => e.methods.includes('GET')).args.per_page.maximum

  const directoryRes = await fetch(`https://theamazingbrentwood.com/wp-json/wp/v2/store?per_page=${shopLimit}`)
  const directory = await directoryRes.json()

  const linkMap = directory.reduce((table, brentwoodTenant) => {
    const slug = brentwoodTenant.slug.split('-').join(' ').replace(/[^0-9a-z]/gi, '')

    const title = he.decode(brentwoodTenant.title.rendered.toLowerCase()).replace(/[^0-9a-z]/gi, '')

    const matchingSlug = tenants.find(t => {
      const tenantName = t.name.toLowerCase().replace(' - coming soon', '').replace(/[^0-9a-z]/gi, '')
      return tenantName.includes(slug) || tenantName.includes(title) || title.includes(tenantName || slug.includes(tenantName)) || title.includes(t.brentwoodtitle)
    })

    if (matchingSlug) {
      return {
        ...table,
        [brentwoodTenant.id]: `${matchingSlug._id}`,
        [`cms-${matchingSlug._id}`]: brentwoodTenant
      }
    }

    // console.log(`No match for ${title}`)
    return {
      ...table
    }
  }, {})

  // Special case for DQ
  linkMap[206] = '69737'

  const dqTenant = directory.find(t => t.id === 206)

  if (dqTenant) {
    linkMap['cms-69737'] = dqTenant
  }

  return linkMap
}

export default generateTable